import React from 'react'
import {
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    TextField,
} from "@mui/material";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { imgBaseURL, toastifyError, toastifySuccess } from 'helper/Utility';
import { APICALL } from 'helper/api/api';
import { SERVER_ERR, SOMETHING_ERR } from 'helper/Constant';
import BTNLoading from 'helper/my-com/BTNLoading';
import { useFrontDataContext } from 'helper/context/FrontContextProvider';
import { useEffect } from 'react';
const EditProfileModal = ({ handleCloseModal2 }) => {
    const { userInfo,getUserInfoFun } = useFrontDataContext()

    const [image, setImage] = useState("https://myplo.com/fileStorage/uploads/users/10078/users_1731673884335.png");
    const [activeTab, setActiveTab] = useState("profile");

    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        fullName: userInfo.fullName,
        image: ""
    });

    useEffect(() =>{
        setImage(imgBaseURL() + userInfo?.avatar)
    },[])
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFormData({...formData, 'image' : file})
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleCancel = () => {
        setFormData({
            ...formData,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
        handleCloseModal2()
    }

    const validate = () => {
        const newErrors = {};
        if (!formData.currentPassword) newErrors.currentPassword = "Current password is required.";
        if (!formData.newPassword) newErrors.newPassword = "New password is required.";
        if (!formData.confirmPassword) newErrors.confirmPassword = "Please confirm your password.";
        if (formData.newPassword && formData.confirmPassword && formData.newPassword !== formData.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true)
            try {
                const res = await APICALL('/user/changeProfilePassword', 'post', formData)
                if (res?.status) {
                    toastifySuccess(res?.message)
                    handleCancel()
                } else {
                    toastifyError(SOMETHING_ERR)
                }
            } catch (error) {
                toastifyError(error?.response?.data?.message || SERVER_ERR)
            } finally {
                setLoading(false)
            }
        }
    };

    const [passwordShow, setPasswordShow] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    })
    const passwordToggle = (name) => {
        setPasswordShow((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const updateProfile = async () =>{
        const form = new FormData()
        form.append("fullName", formData.fullName)
        if(formData?.image){
            form.append("image", formData?.image)
        }
        setLoading(true)
        try {
            const res = await APICALL('/user/updateProfile', 'post', form)
            if (res?.status) {
                toastifySuccess(res?.message)
                getUserInfoFun()
                handleCancel()
            } else {
                toastifyError(SOMETHING_ERR)
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || SERVER_ERR)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div
                className="modal  fade show edit_dialog_modal"
                style={{
                    display: "block",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    inset: "0px",
                }}
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content my_form">
                        <div className="modal-header mx-4 pt-3 px-0 pb-4">
                            <button className="cs_close close me-0" onClick={handleCloseModal2}> <i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div className="modal-body">
                            <nav>
                                <div
                                    className="nav nav-tabs nav-fill"
                                    id="nav-tab"
                                    role="tablist"
                                >
                                    <button
                                        className={`nav-item nav-link ${activeTab === "profile" ? "active" : ""
                                            }`}
                                        id="nav-home-tab"
                                        onClick={() => setActiveTab("profile")}
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected={activeTab === "profile"}
                                    >
                                        Profile
                                    </button>
                                    <button
                                        className={`nav-item nav-link ${activeTab === "password" ? "active" : ""
                                            }`}
                                        id="nav-profile-tab"
                                        onClick={() => setActiveTab("password")}
                                        role="tab"
                                        aria-controls="nav-profile"
                                        aria-selected={activeTab === "password"}
                                    >
                                        Password
                                    </button>
                                </div>
                            </nav>
                            <div className="tab-content overall-mg" id="nav-tabContent">
                                <div className={`tab-pane fade ${activeTab === "profile" ? "show active" : ""}`}
                                    id="nav-home1" role="tabpanel" aria-labelledby="nav-home-tab">
                                    {/* Profile Content */}
                                    <div className="form-editprofile">
                                        <div className="imageUpload">
                                            {/* File Input */}
                                            <div className="fileinput">
                                                <input
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={handleImageChange}
                                                    style={{ display: "none" }}
                                                    id="file-upload"
                                                />
                                                {/* Thumbnail */}
                                                <div className="thumbnail cls_round">
                                                    <label htmlFor="file-upload">
                                                        <img
                                                            src={formData?.image ? URL.createObjectURL(formData?.image) : image}
                                                            alt="Profile"
                                                            height="75"
                                                            width="100"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            {/* Info Text */}
                                            <div>
                                                <span>Tap the photo to change it</span>
                                                <br />
                                                <span>Images must be in PNG or JPG format</span>
                                            </div>
                                        </div>
                                        {/* Name Field */}
                                        <FormControl variant="standard">
                                            <InputLabel htmlFor="name-with-icon">Name</InputLabel>
                                            <Input
                                                id="name-with-icon"
                                                value={formData?.fullName}
                                                name='fullName'
                                                onChange={handleChange}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                            <path d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z"></path>
                                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                                        </svg>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                        {/* Email Field */}
                                        <FormControl variant="standard">
                                            <InputLabel htmlFor="email-with-icon">
                                                Email
                                            </InputLabel>
                                            <Input
                                                id="email-with-icon"
                                                type="email"
                                                disabled
                                                value={userInfo?.email}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" >
                                                            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                                        </svg>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>

                                        <div className="text-center">
                                            {
                                                loading ? <BTNLoading className={"logbtnss-edit"} /> :
                                                    <button type="button" className="logbtnss-edit" onClick={()=>updateProfile()}> Update Profile </button>
                                            }
                                            <Link to={''} className="log_out">Log out</Link>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`tab-pane fade ${activeTab === "password" ? "show active" : ""}`}
                                    id="nav-profile1" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    {/* Password Content */}
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-editprofile">
                                            {/* Current Password Field */}
                                            <FormControl variant="standard" error={!!errors.currentPassword}>
                                              <div className='main_profile_edit'>
                                              <InputLabel htmlFor="current-password">Current password</InputLabel>
                                                <Input
                                                    id="current-password"
                                                    className='w-100'
                                                    name="currentPassword"
                                                    type={`${passwordShow?.currentPassword ? "text" : "password"}`}
                                                    value={formData.currentPassword}
                                                    onChange={handleChange}
                                                    startAdornment={
                                                        <>
                                                            <InputAdornment position="start">
                                                                <svg
                                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1wmkh38"
                                                                    focusable="false"
                                                                    aria-hidden="true"
                                                                    viewBox="0 0 24 24"
                                                                    data-testid="LockOutlinedIcon"
                                                                >
                                                                    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9zm9 14H6V10h12zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2"></path>
                                                                </svg>
                                                            </InputAdornment>

                                                        </>
                                                    }
                                                />
                                             <div className='show_hide'>
                                             <i className={`fa  ${passwordShow?.currentPassword ? "fa-eye-slash" : "fa-eye"}`} style={{ cursor: "pointer" }} onClick={() => passwordToggle("currentPassword")} />
                                             </div>
                                                {errors.currentPassword && <span className="error-text">{errors.currentPassword}</span>}
                                              </div>
                                            </FormControl>

                                            {/* New Password Field */}
                                            <FormControl variant="standard" error={!!errors.newPassword}>
                                            <div className='main_profile_edit'>
                                                <InputLabel htmlFor="new-password">New password</InputLabel>
                                                <Input
                                                    id="new-password"
                                                    name="newPassword"
                                                    className='w-100'
                                                    type={`${passwordShow?.newPassword ? "text" : "password"}`}
                                                    value={formData.newPassword}
                                                    onChange={handleChange}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <svg
                                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1wmkh38"
                                                                focusable="false"
                                                                aria-hidden="true"
                                                                viewBox="0 0 24 24"
                                                                data-testid="LockOutlinedIcon"
                                                            >
                                                                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9zm9 14H6V10h12zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2"></path>
                                                            </svg>
                                                        </InputAdornment>
                                                    }
                                                />
                                                         <div className='show_hide'>
                                                <i className={`fa  ${passwordShow?.newPassword ? "fa-eye-slash" : "fa-eye"}`} style={{ cursor: "pointer" }} onClick={() => passwordToggle("newPassword")} />
                                                </div>
                                                {errors.newPassword && <span className="error-text">{errors.newPassword}</span>}
                                            
                                                </div>
                                            </FormControl>

                                            {/* Confirm Password Field */}
                                            <FormControl variant="standard" error={!!errors.confirmPassword}>
                                            <div className='main_profile_edit'>
                                                <InputLabel htmlFor="confirm-password">Confirm password</InputLabel>
                                                <Input
                                                className='w-100'
                                                    id="confirm-password"
                                                    name="confirmPassword"
                                                    type={`${passwordShow?.confirmPassword ? "text" : "password"}`}

                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <svg
                                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1wmkh38"
                                                                focusable="false"
                                                                aria-hidden="true"
                                                                viewBox="0 0 24 24"
                                                                data-testid="LockOutlinedIcon"
                                                            >
                                                                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9zm9 14H6V10h12zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2"></path>
                                                            </svg>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <div className='show_hide'>
                                                <i className={`fa  ${passwordShow?.confirmPassword ? "fa-eye-slash" : "fa-eye"}`} style={{ cursor: "pointer" }} onClick={() => passwordToggle("confirmPassword")} />
                                                </div>
                                                {errors.confirmPassword && <span className="error-text">{errors.confirmPassword}</span>}
                                                </div>
                                            </FormControl>

                                            <div className="text-center">
                                                {
                                                    loading ? <BTNLoading className={"logbtnss-edit"} /> :
                                                        <button type="submit" className="logbtnss-edit"> Submit </button>
                                                }
                                                <Link to={""} className="log_out">Log out </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditProfileModal