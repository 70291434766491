import { APICALL } from 'helper/api/api';
import { currencySign, SERVER_ERR, SOMETHING_ERR } from 'helper/Constant';
import BTNLoading from 'helper/my-com/BTNLoading';
import ConfirmDelete from 'helper/my-com/ConfirmDelete';
import { auth, defaultIMG, emptyIMG, formatdedDate, imgBaseURL, timeAgo, toastifyError, toastifySuccess } from 'helper/Utility';
import React from 'react'
import { useState } from 'react';
import {
    Col,
    Row,
    Table,
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import RateBtn from 'views/Component/RateBtn';

const Orders = ({dashboardDetails}) => {
    const [bookingList, setBookingList] = useState([]);
    useEffect(() =>{
        setBookingList(dashboardDetails?.purchasedOrders)
    },[])
    return (
        <>
         <div className=" selller_edit_sellpromain">
                <div className="nn_edit_proname">Orders</div>
                <div className="seller_edit_sellpro">
                    {
                        bookingList?.length > 0 ?
                            <Row className="seller_edit_sellpro_inner">
                                <div className="nn_order_details-pay table-responsive p-0">
                                    <Table className="mb-0">
                                        <thead>
                                            <tr>
                                                <th>Product Title</th>
                                                <th>Price</th>
                                                <th>Order Date</th>
                                                <th className="text-end"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList?.map((item, idx) => (
                                                <>
                                                    <tr>
                                                        <td width="30%">
                                                            <div className="imgwithtitle">
                                                                <img src={item?.productId?.gallery?.length > 0 ? imgBaseURL() + item?.productId?.gallery[0] : defaultIMG} alt="" />{item?.productId?.title}
                                                            </div>
                                                        </td>
                                                        <td>{currencySign}{item?.total}</td>
                                                        <td>{timeAgo(item?.createdAt)}</td>
                                                        <td  className="text-end"> {!item?.isRating ?<RateBtn className="" data={item}/>:<p className='alredy_raded'>Rated</p>} </td>
                                                    </tr>
                                                </>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                            :
                            <div className="nn_empty_tab text-center">
                                <img src={emptyIMG} alt="Empty" />
                                <p>
                                    <span>Sorry...</span> You have no favourite product yet.
                                </p>
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Orders