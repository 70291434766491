import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import roundcircle from "../../assets/images/succes.gif"
import productphot from "../../assets/images/gigss.png"
import stripe from "../../assets/images/stripe.png"
import { useLocation } from "react-router"

const OrderSuccess = () => {
    const locationData = useLocation()
    console.log("orderDetails", locationData)
    return (
        <div className="order_succes_page">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="box_order">
                            <div class="top_heading_glob text-center">
                                <img src={roundcircle} alt="sucess" />
                                <h1 class="mb-1">Order Successfully !!</h1>
                                <p>Your order has been confirmed </p>
                            </div>

                            <div>
                                <ul class="amount_description">
                                    <li>
                                        <div class="amount_details">Payment Method</div>
                                        <div class="amount_price">
                                          <img src={stripe} height={"22px"} alt="payment_icon"/>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="amount_details">Order Id</div>
                                        <div class="amount_price">#29</div>
                                    </li>
                                    <li>
                                        <div class="amount_details">Item</div>
                                        <div class="amount_price">1</div>
                                    </li>

                                    <li>
                                        <div class="amount_details">Total</div>
                                        <div class="amount_price">$28.8</div>
                                    </li>
                                </ul>

                                <div class="mt-4">
                                    <h6>Ordered Item Details</h6>
                                    <div class="cart_items_details_box mt-3">
                                        <div class="cart_product_box">
                                            <div class="cart_product_image">
                                                <img src={productphot} alt="..." />
                                            </div>
                                            <div class="cart_product_details">
                                                <div>
                                                    <h3>Razer Basilisk V3 Customizable Ergonomic Gaming Mouse: Fastest Gaming Mouse Switch - Chroma RGB Lighting - 26K DPI Optical Sensor - 11 Programmable Buttons - HyperScroll Tilt Wheel - Classic Black</h3>
                                                    <p>amazon.com</p>
                                                </div>
                                                <div>
                                                    <span>$24</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OrderSuccess
