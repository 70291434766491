import React, { useState } from "react";

// import { useState } from "react";
import { Breadcrumb, Col, Container, Dropdown, Row } from "react-bootstrap";
// import OwlCarousel from "react-owl-carousel";
import playstore from "../../assets/img/badge_googleplay.svg";
import appstore from "../../assets/img/badge_appstore.svg";
import myploscreen from "../../assets/img/footerBatch.png";
import userdefault from "../../assets/img/userdefault.png";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import img2 from "../../assets/img/products2.jpg";
import img3 from "../../assets/img/products3.jpg";
import img4 from "../../assets/img/products4.jpg";
import img5 from "../../assets/img/products5.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import ThumbSlider from "../Component/ThumbSlider";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { useEffect } from "react";
import { APICALL } from "helper/api/api";
import { auth, defaultUserIMG, timeAgo, toastifyError, toastifySuccess } from "helper/Utility";
import FrontPageLoader from "helper/my-com/FrontPageLoader";
import { currencySign, currencyText, GOOGLE_MAP_API_KEY, GOOGLE_MAP_BASE_URL, SERVER_ERR, SOMETHING_ERR } from "helper/Constant";
import { useFrontDataContext } from "helper/context/FrontContextProvider";
import ProductBox from "views/Component/ProductBox";
import FrontNoData from "views/Component/FrontNoData";
import LoginAuth from "views/Component/LoginModal";
import BTNLoading from "helper/my-com/BTNLoading";
import AppPlayStore from "views/Component/AppPlayStore";
const ProductDetails = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { addProductInWishlistFun, reportProductFun } = useFrontDataContext()
  const { id, role } = useParams()
  const [productDetails, setProductDetails] = useState(null)
  const [isFavourite, setIsFavourite] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [shareUrl] = useState(window.location.href);
  const [loginModal, setLoginModal] = useState(false)
  const handleCloseLoginModal = () => setLoginModal(false);

  const navigate = useNavigate()

  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState({
    'slot': false,
    'bookSlot': false,
  })
  const [offerLoading, setOfferLoading] = useState(false)
  const [slotMsg, setSlotMsg] = useState("")
  const [formData, setFormData] = useState({
    productId: id,
    date: '',
    startFrom: '',
    endTo: '',
    timeTaken: '',
  });
  const getProductDetailsFunc = async () => {
    setLoading(true)
    try {
      const res = await APICALL('/user/getProductByProductId', 'post', { productId: id })
      if (res?.status) {
        const produtctDD = res?.data
        setProductDetails(res?.data)
        setIsFavourite(res?.data?.isFavourite)
        setFormData({
          ...formData,
          'endTo': produtctDD?.endTo,
          'startFrom': produtctDD?.startFrom,
          'timeTaken': produtctDD?.timeTaken,
        })
      } else {
        setProductDetails(null)
      }
    } catch (error) {
      setProductDetails(null)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getProductDetailsFunc()
  }, [])


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setIsCopied(false);
  };

  const copyStatus = () => {
    setIsCopied(true);
  };
  const [isVisible, setIsVisible] = useState(false);
  const [offerPrice, setOfferPrice] = useState("");

  const handleMakeOfferClick = () => {
    if (auth("user")) {
      setIsVisible(!isVisible);
    } else {
      setLoginModal(true)
    }
  };

  const handleSendMessage = async () => {
    if (offerPrice.trim() == "") {
      toastifyError("Please enter offer price")
      return false
    }
    if (offerPrice > productDetails?.price) {
      toastifyError("Your offer price more than product price")
      return false
    }
    setOfferLoading(true)
    try {
      const res = await APICALL('/user/makeOffer', 'post', { productId: id, requestedAmount: offerPrice })
      if (res?.status) {
        toastifySuccess(res?.message)
        setOfferPrice("")
        setIsVisible(false)
      } else {
        toastifyError(SOMETHING_ERR)
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message || SERVER_ERR)
    } finally {
      setOfferLoading(false)
    }
  };

  const mapUrl = `${GOOGLE_MAP_BASE_URL}?center=${encodeURIComponent(productDetails?.location)}&zoom=15&size=600x300&markers=color:red|label:A|${encodeURIComponent(productDetails?.location)}&key=${GOOGLE_MAP_API_KEY}`;

  const handleRedirect = (type) => {
    if (type === "chat") {
      auth('user') ?
        navigate(`/chat/conversation`, { state: productDetails })
        :
        setLoginModal(true)
    } else {
      navigate(`/order-view`, { state: productDetails })
    }
  }
  const [stripErr, setStripErr] = useState(null);
  const amount10 = (productDetails?.price * 10) / 100


  const [slots, setSlots] = useState([])
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    const firstAvailableSlot = slots.find((slot) => slot.available);
    if (firstAvailableSlot) {
      setSelectedSlot(firstAvailableSlot.id);
    }
  }, [slots]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "time-slot") {
      setSelectedSlot(value)
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    if (formData?.date) {
      getSlotFun()
    }
  }, [formData?.date])

  const getSlotFun = async () => {
    setLoader({ ...loader, 'slot': true })
    try {
      const res = await APICALL('admin/getSlotsAvailability', 'post', formData)
      if (res?.status) {
        setSlots(res?.data)
        if (res?.data?.length == 0) {
          setSlotMsg(res?.message)
        }
      } else {
        setSlots([])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoader({ ...loader, 'slot': false })
    }
  }

  const bookSlotFun = async () => {
    setLoader({ ...loading, 'bookSlot': true })
    const res = await APICALL("user/createPaymentInstant", "post", { amount: amount10 });
    if (res?.status == 200) {
      const clientSecret = res?.data?.paymentIntent
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload?.paymentIntent?.status == "succeeded") {
        setStripErr(null);
        const params = {
          "vendorId": productDetails?.userId?._id,
          "productId": productDetails?._id,
          "date": formData?.date,
          "slot": selectedSlot,
          "platformFee": "10",
          "total": amount10,
          "paymentMethod": "stripe",
          "paymentResponse": {
            "status": "success",
            "transactionId": "TRANSACTION_ID",
            "amount": amount10,
            "currency": "USD"
          }
        }

        try {
          const res = await APICALL('/admin/createBooking', 'post', params)
          if (res?.status) {
            toastifySuccess(res?.message)
            navigate(`/profile/${auth("user")?.id}`)
          } else {
            toastifyError(SOMETHING_ERR)
          }
        } catch (error) {
          toastifyError(error?.response?.data?.message || SERVER_ERR)
        } finally {
          setLoader({ ...loading, 'bookSlot': false })
        }
      } else {
        setStripErr(`Payment failed - Somthing wrong !!`);
        setLoader({ ...loading, 'bookSlot': false })
      }
      if (payload.error) {
        setStripErr(`Payment failed ${payload.error.message}`);
        setLoader({ ...loading, 'bookSlot': false })
      }
    }
  }

  return (
    <>
      <div className="margindiv-header">

        <section className="prodcuts_Details_page">
          {
            loading ? <FrontPageLoader />
              :
              <Container>
                <Breadcrumb className="breadcrumbs_pro">
                  <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/product-listing/${productDetails?.categoryId?._id}`}>
                      {productDetails?.categoryId?.categoryName}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {productDetails?.title}
                  </Breadcrumb.Item>
                </Breadcrumb>

                <div className="prodcuts_Details_inner">
                  <Row>
                    <Col lg="6" className="mb-lg-0 mb-4">
                      <ThumbSlider images={productDetails?.gallery} />
                      <div className="proDescription">
                        <h1>Description </h1>
                        <p> {productDetails?.description} </p>
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="prodcuts_Details_right">
                        <div className="details_oUter">
                          <div className="flex-categries-pri">
                            <div className="nn_pro_amt">
                              <h2> {productDetails?.title}</h2>
                              <h3>Price :{" "}<span className="nn_currency"> {currencySign}{productDetails?.price} </span></h3>
                            </div>

                            <div className="nn_pro_share">
                              <ul>
                                {
                                  auth("user") ?
                                    <li className="myplochatimg my-0">
                                      {
                                        isFavourite ?
                                          <button className="favbtn" onClick={() => { addProductInWishlistFun(productDetails?._id); setIsFavourite(!isFavourite) }}> <i className="fa fa-heart active"></i> </button>
                                          :
                                          <button className="" onClick={() => { addProductInWishlistFun(productDetails?._id); setIsFavourite(!isFavourite) }}><i className="fa fa-heart"></i></button>
                                      }
                                    </li>
                                    :
                                    <li className="myplochatimg my-0">
                                      <button className="" onClick={() => { setLoginModal(true) }}><i className="fa fa-heart"></i></button>
                                    </li>
                                }
                                <li>
                                  <button className="" onClick={togglePopup}>
                                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                      <path fill="none" d="M0 0h24v24H0z"></path>
                                      <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
                                    </svg>
                                  </button>
                                </li>


                                {/* Popup Component */}
                                {isPopupOpen && (
                                  <div className="discard-popup">
                                    <div className="popup-content">
                                      <div className="close-btn">
                                        {" "}
                                        <button onClick={togglePopup}> <i className="fa fa-times"></i> </button>
                                      </div>
                                      <div className="popup-header"><h3>Share this listing</h3> </div>
                                      <div className="input-group">
                                        <svg viewBox="0 0 24 24" width="24" height="24" className="imgbgclg">
                                          <path
                                            d="M8.994 13.713a1.368 1.368 0 1 1 2.19-1.638 3.103 3.103 0 0 0 4.68.335l2.666-2.666a3.103 3.103 0 0 0-.038-4.35C17.296 4.2 15.36 4.18 14.156 5.343l-1.538 1.529a1.368 1.368 0 1 1-1.929-1.94l1.552-1.543a5.839 5.839 0 0 1 8.185.071c2.254 2.254 2.282 5.896.054 8.202l-2.682 2.682a5.839 5.839 0 0 1-8.804-.63zm5.767-3.426a1.368 1.368 0 1 1-2.19 1.638 3.103 3.103 0 0 0-4.68-.335l-2.666 2.666a3.103 3.103 0 0 0 .037 4.35c1.195 1.195 3.13 1.215 4.334.054l1.529-1.529a1.368 1.368 0 1 1 1.934 1.934l-1.546 1.546a5.839 5.839 0 0 1-8.185-.071c-2.254-2.254-2.281-5.896-.054-8.202l2.682-2.682a5.839 5.839 0 0 1 8.805.63z"
                                            fill="rgb(158, 158, 158)"
                                          ></path>
                                        </svg>
                                        <input type="text" className="form-control" value={shareUrl} readOnly />
                                        <CopyToClipboard text={shareUrl} onCopy={copyStatus}>
                                          <button className="btn copy-btn">
                                            {isCopied ? "Copied!" : "Copy"}
                                          </button>
                                        </CopyToClipboard>
                                      </div>

                                      <div className="social-buttons">
                                        <FacebookShareButton url={shareUrl}>
                                          <button className="btn facebook-btn">
                                            <svg viewBox="0 0 24 24" width="24" height="24">
                                              <path d="M12 0C5.373 0 0 5.395 0 12.05c0 5.97 4.326 10.912 9.999 11.87v-9.356H7.104v-3.366h2.895V8.715c0-2.88 1.752-4.45 4.31-4.45 1.226 0 2.28.092 2.585.133v3.01l-1.775.001c-1.391 0-1.66.664-1.66 1.638v2.149h3.32l-.432 3.367H13.46V24C19.397 23.274 24 18.205 24 12.047 24 5.395 18.627 0 12 0z"></path>
                                            </svg>
                                          </button>
                                        </FacebookShareButton>
                                        <WhatsappShareButton url={shareUrl}>
                                          <button className="btn whatsapp-btn">
                                            <svg viewBox="0 0 48 48" width="100" height="100" >
                                              <path
                                                fill="#fff"
                                                d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                                              ></path>
                                              <path
                                                fill="rgb(4, 167, 78)"
                                                fill-rule="evenodd"
                                                d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </button>
                                        </WhatsappShareButton>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <li className="drop-btn dropdown noresponsivew nn_dropdn">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <i className="fa fa-ellipsis-vertical"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#" onClick={() => { auth("user") ? reportProductFun(productDetails?._id) : setLoginModal(true) }}>
                                        Report listing
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </li>

                              </ul>

                            </div>
                          </div>

                          {
                            role ?
                              <>
                                <div className="mb-3 row g-2">
                                  <div className="mb-sm-0 mb-3 col-sm-12">
                                    <input type="date" className="form-control" name="date" value={formData.date}
                                      onChange={handleChange} min="2025-01-09" />
                                  </div>

                                  {
                                    slots?.length > 0 ?
                                      <div className="mt-3 mb-3">
                                        <label>Available slots</label>
                                        <div className="time-slot-selector">
                                          {slots?.map((slot, key) => (
                                            <label key={key} className={`slot-button ${selectedSlot == slot.timeRange ? "active" : ""} ${slot.isBooked ? "disabled" : ""}`}>
                                              <input
                                                type="radio"
                                                name="time-slot"
                                                value={slot.timeRange}
                                                checked={selectedSlot == slot.timeRange}
                                                onChange={handleChange}
                                                disabled={slot.isBooked}
                                                className="radio-input"
                                              />
                                              {slot.timeRange}
                                            </label>
                                          ))}
                                        </div>
                                      </div>
                                      :
                                      <>
                                        <p className="text-danger">{slotMsg}</p>
                                      </>
                                  }
                                  {
                                    selectedSlot &&
                                    <div className="nn_pro_chatbtn nn_buybtn btn-wrapper mb-3">
                                      <button className="cmn-btn-outline2" onClick={handleMakeOfferClick} >Pay Retainer</button>
                                    </div>
                                  }
                                  <p>
                                    Lock in your dream vendor today! Deposits are final,
                                    but ensure you secure your vendor(s). Final payments
                                    happen directly with the vendor(s), using their
                                    preferred method.
                                  </p>
                                </div>
                                {isVisible && (
                                  <div className="mt-3">
                                    <label>Amount</label>
                                    <input className="form-control" type="text" value={`${currencyText} ${amount10}`} />
                                    <div className="row mt-4">
                                      <div className="col-12">
                                        <CardElement className="card-stripe-element" />
                                        {stripErr && (
                                          <div className="stripe-error-msg mt-2 text-danger">
                                            {stripErr}
                                          </div>
                                        )}
                                        <div className="mt-4">
                                          <span>Secure payments are handled by Stripe. We never even see your card details.</span>
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      loader?.bookSlot ? <BTNLoading className={"cmn-btn-outline2 mt-3"} /> :
                                        <button className="cmn-btn-outline2 mt-3" onClick={bookSlotFun} >Send</button>
                                    }
                                  </div>
                                )}
                              </>
                              :
                              <>
                                <div className="cls_cateprolist">
                                  <ul>
                                    <li>  <b>Condition: </b> {productDetails?.condition}</li>
                                    <li><b>Weight (Max 30 Lbs): </b> {productDetails?.weight?.max}</li>
                                  </ul>
                                </div>
                                <div className="btn-wrapper mb-3">
                                  <button onClick={() => handleRedirect()} type="button" className="cmn-btn-outline2"> Buy Now</button>
                                  <button className="cmn-btn-outline2" onClick={() => handleMakeOfferClick()}> Make an offer </button>
                                </div>
                                {isVisible && (
                                  <div className="mt-3">
                                    <label>Enter Offer Price:</label>
                                    <input className="form-control" value={offerPrice} onChange={(e) => setOfferPrice(e.target.value)} type="number" placeholder="Enter Price" />
                                    {
                                      offerLoading ?
                                        <BTNLoading className={"cmn-btn-outline2 mt-3"} />
                                        :
                                        <button className="cmn-btn-outline2 mt-3" onClick={handleSendMessage} > Send</button>
                                    }
                                  </div>
                                )}
                              </>
                          }

                        </div>


                        <div className="details_oUter">
                          <div className="recentCaption">
                            {
                              role ?
                                <div className="row">
                                  <div className="col-sm-4">
                                    <div>
                                      <p className="featureCap">
                                        <strong className="subCap">Owner</strong>
                                      </p>
                                      <Link to="#">
                                        {" "}
                                        <h5 className="featureTittle">{productDetails?.userId?.fullName}</h5>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-sm-8">
                                    <button className="cmn-btn2 w-100" type="button" onClick={() => handleRedirect("chat")}>
                                      chat Now
                                    </button>
                                  </div>
                                </div>
                                :
                                <>
                                  <p className="featureCap">
                                    <strong className="subCap">Sold By</strong>
                                  </p>
                                  <h5 className="featureTittle">{productDetails?.userId?.fullName}</h5>
                                  <span className="featurePricing mb-3 d-block mt-3">
                                    <i className="far fa-clock me-1"></i> Posted On : {timeAgo(productDetails?.createdAt)}
                                  </span>
                                  <div className="nn_prochatbtn1 butonChat">
                                    <Link to={`/profile/${productDetails?.userId?._id}`}>
                                      <img src={userdefault} className="float-left" alt="" />
                                    </Link>
                                    <button className="nn_chatname chatname-bg text-truncate" type="button" onClick={() => handleRedirect("chat")}>
                                      Chat With {productDetails?.userId?.fullName}
                                    </button>
                                  </div>
                                </>
                            }

                          </div>
                        </div>


                      </div>
                    </Col>
                  </Row>
                </div>

                {
                  role ?
                    <div class="nn_map_details">
                      <div className="myplo-title-h2 d-block mb-4">
                        <h2 className="ps-0 mb-2">
                          <span className="shape"></span>Vendor Information
                        </h2>
                      </div>
                      <div className="reviewpage mt-4">
                        <div className="reviewpageinner">
                          <img src={defaultUserIMG} alt="" />
                          <div>
                            <h4> {productDetails?.userId?.fullName}</h4>
                            <p> {productDetails?.userId?.fullName}</p>
                          </div>
                        </div>
                        <Link to="#" className="cmn-btn2">
                          Contact Me
                        </Link>
                      </div>
                    </div>
                    :
                    <div className="map_prodcts">
                      <h2>
                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                          <path fill="none" d="M0 0h24v24H0V0z"></path>
                          <g>
                            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path>
                            <circle cx="12" cy="9" r="2.5"></circle>
                          </g>
                        </svg>
                        category in
                      </h2>
                      <div className="map_prodcts_inner">
                        <img src={mapUrl} alt="Map" />
                      </div>
                    </div>
                }

                <div className="related-products">
                  <div className="myplo-title-h2 d-block mb-4">
                    <h2 className="ps-0 mb-2">
                      <span className="shape"></span>Related Products
                    </h2>
                  </div>
                  <div className="row">
                    {
                      productDetails?.relatedProducts?.length > 0 ?
                        productDetails?.relatedProducts?.map((item, i) => (
                          <div className="col-lg-3 col-md-3 col-sm-6  mb-4" key={i}>
                            <ProductBox item={item} />
                          </div>
                        ))
                        :
                        <FrontNoData msg={" OOPS! THERE ARE NO RELATED PRODUCTS"} />
                    }
                  </div>
                </div>
              </Container>
          }
        </section>
        <AppPlayStore />
      </div>
      <LoginAuth show={loginModal} handleClose={handleCloseLoginModal} />

    </>
  );
};

export default ProductDetails;
