import React, { useState } from "react"
import SlidingPane from "react-sliding-pane"
import { DropzoneComponent } from "react-dropzone-component"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { GoogleMap, MarkerF, InfoWindow, Circle, MarkerClusterer } from "@react-google-maps/api"
import { APICALL } from "helper/api/api"
import { currencySign, SOMETHING_ERR } from "helper/Constant"
import { auth, toastifyError, toastifySuccess } from "helper/Utility"
import TextField from "@mui/material/TextField"
import { useEffect } from "react"
import InputLabel from "@mui/material/InputLabel"
import Slider from "@mui/material/Slider"
import Select from "@mui/material/Select"
import { Box, FormControl, MenuItem, OutlinedInput, Typography } from "@mui/material"
import { Card, Col, Form, FormLabel, Row } from "react-bootstrap"
import LoginAuth from "./LoginModal"
import BTNLoading from "helper/my-com/BTNLoading"
import { useFrontDataContext } from "helper/context/FrontContextProvider"

const previewTemplate = `
  <div class="dz-preview dz-file-preview dz-clickable fx_img nn_selling_img">
    <div class="dz-details">
      <div class="img_cvr nn_stuffimg">
        <img data-dz-thumbnail alt="" />
        <button data-dz-remove>
          <svg viewBox="0 0 24 24">
            <path d="M5.176 6.706a1.176 1.176 0 1 1 0-2.353h4.706a2.353 2.353 0 0 1 4.706 0h4.706a1.176 1.176 0 1 1 0 2.353V20a2 2 0 0 1-2 2H7.176a2 2 0 0 1-2-2V6.706zm4.706 2.353c-.65 0-1.176.421-1.176.941v7.53c0 .52.527.94 1.176.94.65 0 1.177-.42 1.177-.94V10c0-.52-.527-.941-1.177-.941zm4.706 0c-.65 0-1.176.421-1.176.941v7.53c0 .52.526.94 1.176.94.65 0 1.177-.42 1.177-.94V10c0-.52-.527-.941-1.177-.941z"></path>
          </svg>
        </button>
      </div>
      <div class="dz-error-message">
        <span style="color: red" data-dz-errormessage></span>
      </div>
    </div>
  </div>
`

const dictDefaultMessage = `
  <div class="dz-clickable">
    <div class="dropZoneDefault">
      <svg viewBox="0 0 24 24" width="48px" height="48px" fill="#EEEEEE">
        <path d="M19.389 18.3V5.1c0-.221-.194-.4-.425-.4H4.514c-.235 0-.425.176-.425.4v12.786l4.098-5.802c.261-.37.728-.402 1.046-.075l4.233 4.358 1.523-1.438a.836.836 0 0 1 1.175.022l3.225 3.349zm0-15.3c.938 0 1.7.893 1.7 1.995v14.01c0 1.102-.762 1.995-1.7 1.995h-15.3c-.939 0-1.7-.893-1.7-1.995V4.995C2.389 3.893 3.15 3 4.089 3h15.3zm-5.525 7.275a1.7 1.7 0 1 1 0-3.4 1.7 1.7 0 0 1 0 3.4z"></path>
      </svg>
      <h1><span>Drag and drop, or browse </span></h1>
      <p><span>Upload up to 10 photos or 1 video of what you're selling.</span></p>
    </div>
  </div>
`
const MAX = 30
const MIN = 0
const marks = [
    {
        value: MIN,
        label: ""
    },
    {
        value: MAX,
        label: ""
    }
]
const SellYourPartyleftover = ({ isPaneOpen, setIsPaneOpen }) => {
    const { contextLoader, getCategoryListFunc, categoryList, stripeConnectedFun,
        isStripeConncted
     } = useFrontDataContext()
    // const isStripeConncted = true
    const mapContainerStyle = {
        width: "100%",
        height: "30vh"
    }
    const defaultCenter = {
        lat: -25.274398,
        lng: 133.775136
    }
    const [loginModal, setLoginModal] = useState(false)
    const handleCloseLoginModal = () => setLoginModal(false);
    const [searchLatLan, setSearchLatLan] = useState(defaultCenter)
    const [loading, setLoading] = useState(false)
    const enableDropZone = true
    const maxChars = 1500
    useEffect(() => {
        getCategoryListFunc("partySupply")
        stripeConnectedFun()
    }, [])
    const [value, setValue] = useState({
        title: "",
        description: "",
        image: "",
        location: "",
        status: true,
        sellingStatus: "",
        price: "",
        categoryId: "",
        userId: null,
        condition: "",
        weight: MAX,
        latitude: "",
        longitude: "",
    })
    const [images, setImages] = useState([])
    const handleAdd = (file) => {
        auth('user') ?
            setImages((prevImages) => [...prevImages, file])
            :
            setLoginModal(true)
    }

    const handleRemove = (file) => {
        setImages((prevImages) => prevImages.filter((existingFile) => existingFile.name !== file.name))
    }

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target
        setValue((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value
        }))
    }
    const [errors, setErrors] = useState({})

    const validate = () => {
        const newErrors = {}
        if (!value.title.trim()) newErrors.title = "Please enter title."
        if (!value.price.trim()) newErrors.price = "Please enter rate."
        if (!value.condition.trim()) newErrors.condition = "Please select condition"
        if (!value.location.trim()) newErrors.location = "Please enter location"
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!validate()) return
        try {
            setLoading(true)
            const weight = { min: 0, max: value.weight }
            const formData = new FormData()
            if (value?.id) {
                formData.append("id", value.id)
            }
            formData.append("title", value.title)
            formData.append("description", value.description)
            formData.append("location", value.location)
            formData.append("status", value.status)
            formData.append("sellingStatus", value.sellingStatus)
            formData.append("categoryId", value.categoryId)
            formData.append("price", value.price)
            formData.append("weight", JSON.stringify(weight))
            formData.append("userId", auth("user")?.id)
            formData.append("condition", value.condition)
            formData.append("latitude", value.latitude)
            formData.append("longitude", value.longitude)
            images.forEach((file) => {
                formData.append(`gallery`, file)
            })
            const res = await APICALL("/admin/addOrUpdateProduct", "POST", formData)
            if (res?.status) {
                toastifySuccess(res?.message)
                handleClose()
            } else {
                toastifyError(SOMETHING_ERR)
            }
        } catch (error) {
            console.log(error)
            toastifyError(error?.response?.data?.message || "Server Error")
        } finally {
            setLoading(false)
        }
    }

    const handleLocationChange = async (value) => {
        setValue((prevValue) => ({
            ...prevValue,
            location: value
        }))
    }

    const handleLocationSelect = async (value) => {
        try {

            try {
                const results = await geocodeByAddress(value)
                const latLng = await getLatLng(results[0])
                setValue((prevValue) => ({
                    ...prevValue,
                    location: value,
                    latitude: latLng?.lat,
                    longitude: latLng?.lng,
                }))
                setSearchLatLan(latLng)
            } catch (error) {
                console.error("Error fetching location details", error)
            }
        } catch (error) {
            console.error("Error selecting address", error)
        }
    }

    const handleSlideChange = (_, newValue) => {
        setValue((prev) => ({ ...prev, ["weight"]: newValue }))
    }

    const handleClose = () => {
        setValue({
            ...value,
            title: "",
            description: "",
            image: "",
            location: "",
            status: true,
            sellingStatus: "",
            price: "",
            categoryId: "",
            userId: null,
            condition: "",
            weight: 0
        })
        setIsPaneOpen(false)
        setSearchLatLan(defaultCenter)
        setImages([])
    }

    return (
        <div>
            <SlidingPane
                closeIcon={
                    <div onClick={() => handleClose()} className="slide-pane__close lol">
                        <svg viewBox="0 0 24 24">
                            <path d="M12 9.988l3.822-3.822a1.423 1.423 0 0 1 2.011 2.011L14.012 12l3.821 3.822a1.42 1.42 0 0 1 0 2.011 1.42 1.42 0 0 1-2.011 0L12 14.011l-3.822 3.822a1.42 1.42 0 0 1-2.011 0 1.42 1.42 0 0 1 0-2.01L9.988 12 6.167 8.177a1.42 1.42 0 1 1 2.011-2.01L12 9.987z"></path>
                        </svg>
                    </div>
                }
                className="some-custom-class"
                overlayClassName="some-custom-overlay-class"
                isOpen={isPaneOpen}
                title="What are you selling?"
                onRequestClose={() => handleClose()}
            >
                <div className="Popup-cartopen">
                    <Form onSubmit={handleSubmit}>
                        <Row>


                            {/* Category */}
                            <Col xs={12} sm={12}>
                                <FormLabel>

                                    Categories <span style={{ color: "red" }}>*</span>
                                </FormLabel>
                            </Col>

                            <Col xs={12} sm={12}>
                                <FormControl className="input_mui_box" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                    <Select labelId="demo-simple-select-label" value={value.categoryId} label="Selling Status" name="categoryId" onChange={handleChange}>
                                        {categoryList?.map((item, i) => (
                                            <MenuItem value={item?._id}>{item?.categoryName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>

                            {/* Image */}
                            <Col xs={12} sm={12}>
                                <div className="photosuplod-sell mt-4">
                                    <Col xs={12} sm={12}>
                                        <FormLabel>
                                            {" "}
                                            Photos <span style={{ color: "red" }}>*</span>{" "}
                                        </FormLabel>
                                    </Col>
                                    <Card>
                                        <Card.Body>
                                            {enableDropZone && (
                                                <DropzoneComponent
                                                    config={{
                                                        postUrl: "no-url",
                                                        iconFiletypes: [".jpg", ".png", ".mp4", "video/*"],
                                                        showFiletypeIcon: true
                                                    }}
                                                    eventHandlers={{
                                                        addedfile: handleAdd,
                                                        removedfile: handleRemove
                                                    }}
                                                    djsConfig={{
                                                        acceptedFiles: ".png, .jpg, .jpeg, .mp4",
                                                        autoProcessQueue: false,
                                                        clickable: true,
                                                        thumbnailWidth: 400,
                                                        thumbnailHeight: 400,
                                                        previewTemplate,
                                                        dictDefaultMessage
                                                    }}
                                                />
                                            )}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>

                            {
                                !isStripeConncted && (
                                    <Col xs={12} sm={12} className="mt-4">
                                        <p className="text-danger">Stripe account is not connected. Please connect your Stripe account to add products.</p>
                                    </Col>
                                )
                            }

                            {(value?.categoryId && images?.length > 0 && isStripeConncted) && (
                                <>
                                    <Col xs={12} sm={12} className="mt-2">
                                        <FormLabel>
                                            Location <span style={{ color: "red" }}>* {errors?.location}</span>
                                        </FormLabel>
                                    </Col>
                                    <Col xs={12} sm={12}>
                                        <div className="location_relate">
                                            <div>
                                                <PlacesAutocomplete value={value?.location} name="street" onChange={handleLocationChange} onSelect={handleLocationSelect}>
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className="location_input hover_my mb-0">
                                                            <input className="location-cutome-input mb-0" {...getInputProps({ placeholder: "Location" })} />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading ? <div>Loading...</div> : null}
                                                                {suggestions?.map((suggestion) => {
                                                                    const style = {
                                                                        backgroundColor: suggestion.active ? "whitesmoke" : "#fff",
                                                                        padding: "10px 10px",
                                                                        cursor: "pointer"
                                                                    }
                                                                    return (
                                                                        <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                                            {suggestion.description}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </div>

                                            <div className="car-mpas-add">
                                                <GoogleMap
                                                    mapContainerStyle={mapContainerStyle}
                                                    zoom={6.9}
                                                    center={searchLatLan}
                                                    options={{
                                                        disableDefaultUI: true,
                                                        mapTypeControl: false,
                                                        zoomControl: true,
                                                        fullscreenControl: false
                                                    }}
                                                >
                                                    <MarkerF position={searchLatLan} />
                                                </GoogleMap>
                                            </div>
                                        </div>
                                    </Col>

                                    {/* condition */}
                                    <Col xs={12} sm={12} className="mt-2">
                                        <FormLabel>
                                            Condition <span style={{ color: "red" }}>* {errors?.condition}</span>
                                        </FormLabel>
                                    </Col>
                                    <Col xs={12} sm={12} className=" mb-3">
                                        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label">Condition</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={value.condition} onChange={handleChange} label="Condition" name="condition">
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="Excellent">Excellent</MenuItem>
                                                <MenuItem value="Good">Good</MenuItem>
                                                <MenuItem value="Fair">Fair</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>

                                    {/* Weight */}
                                    <Col xs={12} sm={12} className="mt-3 ">
                                        <Box>
                                            <InputLabel id="demo-simple-select-label">Weight (Max 30 Lbs)</InputLabel>
                                            <Slider marks={marks} step={1} value={value?.weight} valueLabelDisplay="auto" min={MIN} max={MAX} onChange={handleSlideChange} />
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            </Box>
                                        </Box>
                                    </Col>

                                    {/* Title */}
                                    <Col xs={12} sm={12} className="mt-3 ">
                                        <FormLabel>
                                            Title <span style={{ color: "red" }}>* {errors?.title}</span>
                                        </FormLabel>
                                    </Col>

                                    <Col xs={12} sm={12} className="mb-3 ">
                                        <TextField placeholder="Title" value={value.title} onChange={handleChange} name="title" variant="standard" className="w-100"
                                            inputProps={{ maxLength: 70 }}
                                        />
                                    </Col>

                                    {/* Description */}
                                    <Col xs={12} sm={12} className="mt-3  ">
                                        <FormLabel>
                                            Description
                                        </FormLabel>
                                    </Col>
                                    <Col xs={12} sm={12} className="">
                                        <TextField className="w-100 mb-3 p-0" onChange={handleChange} name="description" multiline rows={4} id="description" value={value.description} variant="standard" inputProps={{ maxLength: 260 }} />
                                    </Col>

                                    {/* Price */}
                                    <Col xs={12} sm={12} className="mt-3 ">
                                        <FormLabel>
                                            Price({currencySign})<span style={{ color: "red" }}>* {errors?.price}</span>
                                        </FormLabel>
                                    </Col>

                                    <Col xs={12} sm={12} className="">
                                        <TextField placeholder="Price" value={value.price}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9.]/g, '');
                                                handleChange({
                                                    target: {
                                                        name: 'price',
                                                        value: numericValue,
                                                    }
                                                });
                                            }}
                                            name="price" variant="standard" className="w-100 mb-3"
                                            inputProps={{ maxLength: 6 }}
                                            />
                                    </Col>
                                </>
                            )}
                        </Row>
                        {(value?.categoryId && images?.length > 0 && isStripeConncted) && (
                            <div className="footer-filterbtn">
                                {
                                    loading ? <BTNLoading className={"filter_btn-modal"} /> :
                                        <button className="filter_btn-modal" type="submit">Done</button>
                                }
                            </div>
                        )}
                    </Form>
                </div>
            </SlidingPane>
            <LoginAuth show={loginModal} handleClose={handleCloseLoginModal} />

        </div>
    )
}

export default SellYourPartyleftover
