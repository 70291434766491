import React, { useState } from "react";
import { Modal, Button, Container } from "react-bootstrap";
import CategoryList from "./CategoryList";
import { Link } from "react-router-dom";
import InputRange from "react-input-range";
import { Checkbox, FormControlLabel } from "@mui/material";
import "react-input-range/lib/css/index.css";

const FilterModalHeader = ({ show, handleClose }) => {
  const [value, setValue] = useState({ min: 0, max: 30 });

  const [fullscreen] = useState(true);
  return (
    <Modal
      fullscreen={fullscreen}
      show={show}
      onHide={handleClose}
      dialogClassName="Filter_custom-modal"
    >
      <Modal.Header>
        <Button variant="link" onClick={handleClose}>
          <i className="fa fa-arrow-left"></i>
        </Button>{" "}
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-lg-4">
        <Container className="px-0">
          <div className="myplo-title-h2">
            <h2>
              <span className="shape"></span>Party Supplies
            </h2>
            <Link className="see_all-all" to="">
              See All
            </Link>
          </div>
          <div>
            <div className="fitler-cardsadd">
              <select className="form-control" name="category" id="selectval">
                <option value="0001">Choose a Category</option>
                <option value="10000">Anniversary Party Decorations</option>
                <option value="10001">Baby Shower Party Decorations</option>
                <option value="10002">
                  Bachelor Party Decorations and Supplies
                </option>
                <option value="10003">Bar/Bat Mitzvah Party Decorations</option>
                <option value="10004">
                  Birthday Party Decorations and Supplies
                </option>
                <option value="10005">
                  Bridal Shower Party Decorations and Supplies
                </option>
                <option value="10006">Carnival Costumes</option>
                <option value="10007">
                  Character Party Costumes, Decorations and Supplies
                </option>
                <option value="10008">
                  Decades Party Decorations and Supplies
                </option>
                <option value="10009">
                  Dinner Party Decorations and Supplies
                </option>
                <option value="10010">
                  Garden Party Decorations and Supplies
                </option>
                <option value="10011">
                  Gender Reveal Party Decorations and Supplies
                </option>
                <option value="10012">
                  Graduation Party decorations and supplies
                </option>
                <option value="10013">Handmade decorations and supplies</option>
                <option value="10014">
                  Housewarming Party decorations and supplies
                </option>
                <option value="10015">
                  International Party decorations and supplies
                </option>
                <option value="10016">
                  Quinceañera Party dresses, decorations and supplies
                </option>
                <option value="10017">
                  Retirement Party decorations and supplies
                </option>
                <option value="10018">
                  Sports Party decorations and supplies
                </option>
                <option value="10019">
                  Wedding Party decorations and supplies
                </option>
                <option value="10020">
                  Bachelorette Party decorations and supplies
                </option>
                <option value="10021">
                  Luau Party decorations and supplies
                </option>
                <option value="10022">
                  Fiesta Party decorations and supplies
                </option>
                <option value="10023">
                  Holiday Party decorations and supplies
                </option>
                <option value="10024">
                  Prom Party dresses, decorations, and supplies
                </option>
                <option value="10028">TEst</option>
              </select>
              <CategoryList></CategoryList>
            </div>
            <div className="prodcuts_listing_colleft w-100 mt-4">
                <div className="filertcler">
                  <p>FILTERS </p>
                  <button type="submit" className="cleBtmn">
                    Clear Filters
                  </button>
                </div>
                <div className="nn_fliterctn px-3">
                  <label className="d-block mb-2">Condition</label>
                  <FormControlLabel
                    className="labelName-filter"
                    control={<Checkbox defaultChecked />}
                    label="Excellent"
                  />
                  <FormControlLabel
                    className="labelName-filter"
                    control={<Checkbox defaultChecked />}
                    label="Good"
                  />
                  <FormControlLabel
                    className="labelName-filter"
                    control={<Checkbox defaultChecked />}
                    label="Fair"
                  />
                </div>
                <label className="d-block px-3 mb-2">Weight (Max 30 Lbs)</label>
                <div className="inpydeanrr">
                  <InputRange
                    maxValue={30}
                    minValue={0}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                  />
                </div>

                <div className="border-top pt-2">
                  <button type="submit" className="cmn-btn2 w-100">
                    Save Filters
                  </button>
                </div>
              </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default FilterModalHeader;
