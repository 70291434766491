export const TABLE_ROW_PER_PAGE = 10
export const TABLE_PAGINATION_DROPDOWN = [10,20,30,40,50]
export const SOMETHING_ERR="Something wen't wrong"
export const SERVER_ERR="Server Error - Try Again"
export const GOOGLE_CLIENT_ID = "1035956082295-mvham52q68fpkni0jkimv1iacb6lu68t.apps.googleusercontent.com"
export const FB_APP_ID = "1143179496737523"

export const GOOGLE_MAP_API_KEY = "AIzaSyB4T9Uq-lSkN1yQMvOpILSR9QyAn6u0xAM"
export const GOOGLE_MAP_BASE_URL = "https://maps.googleapis.com/maps/api/staticmap"

export const ADMIN_LOGIN = "/"

export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const strongPasswordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&!@#$%^&*()_+[\]{}|;:,.<>/?])[A-Za-z\d@$!%*?&!@#$%^&*()_+[\]{}|;:,.<>/?]{8,}$/;

export const currencySign = "$"
export const currencyText = "USD"
export const VENDOR_URL = "https://myplovendor.itworkshop.in"
// export const VENDOR_URL = "http://localhost:3022"
