import { APICALL } from 'helper/api/api';
import { currencySign, SERVER_ERR, SOMETHING_ERR } from 'helper/Constant';
import BTNLoading from 'helper/my-com/BTNLoading';
import ConfirmDelete from 'helper/my-com/ConfirmDelete';
import { auth, defaultIMG, emptyIMG, imgBaseURL, toastifyError, toastifySuccess } from 'helper/Utility';
import React from 'react'
import { useState } from 'react';
import {
    Col,
    Row,
    Table,
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import img1 from "../../../assets/img/products1.png";

const Offer = ({ dashboardDetails, navigate }) => {
    const authID = auth('user')?.id
    const [offerList, setOfferList] = useState(dashboardDetails?.offer)
    const [dltModal, setDltModal] = useState({
        open: false,
        counterModal: false,
        msg: "",
        loading: false,
        type: "",
    })

    const [params, setParams] = useState({
        id: "",
        status: "",
        sellerCounterAmount: "",
    })


    const handleAction = async (type, item) => {
        if (type === "rejected") {
            setDltModal({ ...dltModal, 'open': true, msg: "Are you sure you want to reject this offer ??" })
        } else if (type === "accepted") {
            setDltModal({ ...dltModal, 'open': true, msg: "Are you sure you want to approve this offer ??" })
        } else {
            setDltModal({ ...dltModal, 'counterModal': true, msg: "Let me explain", type: type })
        }
        setParams({ ...params, id: item?.productId?._id, status: type })
    }

    const handleSubmit = async () => {
        if (dltModal?.type === "countered") {
            if (!params?.sellerCounterAmount || params?.sellerCounterAmount.trim() === "") {
                toastifyError("Please enter a counter amount");
                return false;
            }
            if (isNaN(params?.sellerCounterAmount) || Number(params?.sellerCounterAmount) <= 0) {
                toastifyError("Please enter a valid counter amount (positive number)");
                return false;
            }
        }
        setDltModal({ ...dltModal, 'loading': true })
        try {
            const res = await APICALL('/user/respondToOffer', 'post', params)
            if (res?.status) {
                toastifySuccess(res?.message)
                setOfferList(res?.data)
                setDltModal({ ...dltModal, 'open': false, counterModal: false })
            } else {
                toastifyError(SOMETHING_ERR)
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || SERVER_ERR)
        } finally {
            setDltModal({ ...dltModal, 'loading': false })
        }
    }

    const handleBuyNow = (item) => {
        if (item) {
            const productDetails = { ...item.productId, price: item.sellerCounterAmount };
            navigate("/order-view", { state: productDetails });
        } else {
            console.error("Item is missing or undefined:", item);
        }
    };

    return (
        <>
            <div className=" selller_edit_sellpromain">
                <div className="nn_edit_proname">My Offer & Customer Offer</div>
                <div className="seller_edit_sellpro">
                    {
                        offerList?.length > 0 ?
                            <Row className="seller_edit_sellpro_inner">
                                {/* {offerList?.map((item, idx) => (
                                    <>
                                        <Col lg={3} md={4} sm={6} className=" mb-4" key={idx}>
                                            <div className="product-boxes">
                                                <div className="freeproducts text-capitalize">{item?.status}</div>
                                                <div className="nn_homproductctn">
                                                    <img alt="Product" src={item?.productId?.gallery?.length > 0 ? imgBaseURL() + item?.productId?.gallery[0] : defaultIMG} />
                                                </div>
                                                <div className="product-boxes_inner">
                                                    <h4>{item?.productId?.title}</h4>
                                                    <div>
                                                        <p>{item?.buyerId?.fullName} Amount : ${item?.requestedAmount}</p>
                                                        {
                                                            item?.sellerCounterAmount &&
                                                            <p>{item?.sellerId?.fullName} Amount : ${item?.sellerCounterAmount}</p>
                                                        }
                                                    </div>
                                                    <div className='mt-3'>
                                                        {authID === item?.sellerId?._id ?
                                                            <>
                                                                {item?.status !== "accepted" && <button type='button' onClick={() => handleAction("accepted", item)}>Accept</button>}
                                                                {item?.status !== "rejected" && <button type='button' onClick={() => handleAction("rejected", item)}>Reject</button>}
                                                            </>
                                                            :
                                                            <>
                                                                <button onClick={() => handleBuyNow(item)} type='button'>Buy Now</button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                ))} */}

                                <div className="nn_order_details-pay table-responsive p-0">
                                    <Table className="mb-0">
                                        <thead>
                                            <tr>
                                                <th>Product Title</th>
                                                <th>Price</th>
                                                <th>Offer Price</th>
                                                <th className="text-end">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {offerList?.map((item, idx) => (
                                                <>
                                                    <tr>
                                                        <td width="30%">
                                                            <div className="imgwithtitle">
                                                                <img src={item?.productId?.gallery?.length > 0 ? imgBaseURL() + item?.productId?.gallery[0] : defaultIMG} alt="" />{item?.productId?.title}
                                                            </div>
                                                        </td>
                                                        <td>{currencySign}{item?.productId?.price}</td>
                                                        <td>{currencySign}{item?.requestedAmount}</td>
                                                        <td>
                                                            <div className="d-flexbutton">
                                                                {authID === item?.sellerId?._id ?
                                                                    <>
                                                                        {item?.status !== "accepted" && <button type='button' className='myplo_addressbtn1' onClick={() => handleAction("accepted", item)}>Approve</button>}
                                                                        {item?.status !== "rejected" && <button type='button' className='statuspending' onClick={() => handleAction("rejected", item)}>Reject</button>}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="statusaprprove">Approved</div>
                                                                        <button onClick={() => handleBuyNow(item)} type='button' className='myplo_addressbtn1'>Buy Now</button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}

                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                            :
                            <div className="nn_empty_tab text-center">
                                <img src={emptyIMG} alt="Empty" />
                                <p>
                                    <span>Sorry...</span> You have no favourite product yet.
                                </p>
                            </div>
                    }
                </div>
            </div>

            <ConfirmDelete dltModal={dltModal} setDltModal={setDltModal} callFun={handleSubmit} />
            <>
                {dltModal?.counterModal && (
                    <div className="modal  fade show delete_modal"
                        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)", inset: "0px", }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-2">
                                <div className="modal-body">
                                    <h4>Enter counter amount</h4>
                                    <input type="text" value={params?.sellerCounterAmount} onChange={(e) => setParams({ ...params, sellerCounterAmount: e.target.value })} className='form-control' name='count_amount' maxLength={4} placeholder='Amount' />
                                    <div className="nn_discard_btn">
                                        {dltModal?.loading ? <BTNLoading className={'btn1'} />
                                            : <button className="btn1" onClick={() => handleSubmit()}>Submit</button>
                                        }
                                        <button className="btn2" onClick={() => setDltModal({ ...dltModal, 'counterModal': false })}> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </>
    )
}

export default Offer