import React, { useState } from "react";

import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormLabel,
  Nav,
  Row,
  Tab,
  TabContainer,
  Table,
} from "react-bootstrap";
import userdefault from "../../../src/assets/img/userdefault.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import img1 from "../../../src/assets/img/products1.png";
import EmptyTab from "../../../src/assets/img/empty-tap.jpg";
import imgfav from "../../../src/assets/img/img-heart.svg";
import imgcurrent from "../..//../src/assets/img/img-current.svg";
import imgpay from "../../../src/assets/img/img-heart.svg";
import { auth, defaultIMG, imgBaseURL, timeAgo, toastifyError, toastifySuccess } from "helper/Utility";
import BTNLoading from "helper/my-com/BTNLoading";
import { APICALL } from "helper/api/api";
import { SERVER_ERR, SOMETHING_ERR } from "helper/Constant";
import { useEffect } from "react";
import { useFrontDataContext } from "helper/context/FrontContextProvider";
import EditProfileModal from "views/Component/EditProfileModal";
import PayoutMethod from "./tab-section/PayoutMethod";
import FrontPageLoader from "helper/my-com/FrontPageLoader";
import Offer from "./tab-section/Offer";
import Booking from "./tab-section/Booking";
import Orders from "./tab-section/Orders";
const EditProfile = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { contextLoader, userInfo, getUserInfoFun } = useFrontDataContext()
  const authID = auth('user')?.id
  const [showmodal2, setShowModal2] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const handleOpenModal1 = () => setShowModal1(true);
  const handleCloseModal1 = () => setShowModal1(false);
  const handleOpenModal2 = () => setShowModal2(true);
  const handleCloseModal2 = () => setShowModal2(false);
  const [dashboardDetails, setDashboardDetails] = useState(null);

  useEffect(() => {
    if (id) {
      getUserInfoFun(id)
      getDashboardDetailsFun()
    }
  }, [id])

  const [loading, setLoading] = useState({
    'submit': false,
    "list": false,
    "emailVerify": false,
    "report": false,
  })


  const getDashboardDetailsFun = async () => {
    setLoading({ ...loading, 'list': true })
    try {
      const res = await APICALL("user/userDashboard", "post", { userId: id })
      console.log(res)
      if (res?.status) {
        setDashboardDetails(res?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading({ ...loading, 'list': false })
    }
  }

  const [formValue, setFormValue] = useState({
    "email": auth("user")?.email,
    "feedback": "",
    "type": ""
  })

  const handleHelpNSupport = async (e) => {
    e.preventDefault()
    if (formValue?.feedback == "") {
      toastifyError("Description is required !!")
      return false
    }
    setLoading({ ...loading, 'submit': true })
    try {
      const res = await APICALL("user/submitFeedback", "post", formValue)
      if (res?.status) {
        setFormValue({ ...formValue, 'feedback': "" })
        toastifySuccess(res?.message)
      } else {
        toastifyError(SOMETHING_ERR)
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message || SERVER_ERR)
    } finally {
      setLoading({ ...loading, 'submit': false })
    }
  }

  const verifyEmailRequest = async () => {
    try {
      setLoading({ ...loading, 'emailVerify': true })
      const res = await APICALL('user/verifyEmailRequest', 'post', { email: userInfo?.email })
      if (res?.status) {
        toastifySuccess(res?.message)
      } else {
        toastifyError(SOMETHING_ERR)
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message || SERVER_ERR)
    } finally {
      setLoading({ ...loading, 'emailVerify': false })
    }
  }

  const handleAction = async (action) => {
    if (action === "report") {
      try {
        setLoading({ ...loading, 'report': true })
        const res = await APICALL('user/reportUser', 'post', { reportedUserId: userInfo?._id })
        if (res?.status) {
          toastifySuccess(res?.message)
        } else {
          toastifyError(SOMETHING_ERR)
        }
      } catch (error) {
        toastifyError(error?.response?.data?.message || SERVER_ERR)
      } finally {
        setLoading({ ...loading, 'report': false })
      }
    } else {
      toastifyError("WORK IS PENDING...")
    }
  }

  return (
    <>
      <div className="margindiv-header">
        <div className="Seller_edit_promain">
          <Container fluid>
            <TabContainer defaultActiveKey="selling">
              {
                loading?.list ?
                  <FrontPageLoader />
                  :
                  <Row>
                    <Col xs={12} lg={3}>
                      <nav className="seller_tabs_nav">
                        <div className="seller_profile-img">
                          <div className="seller_edit_pro_img">
                            <img src={userInfo?.avatar ? imgBaseURL() + userInfo?.avatar : userdefault} alt="User" />
                            {/* Edit Icon */}
                            {
                              authID === userInfo?._id &&
                              <div className="nn_edit_profileicon" onClick={handleOpenModal2}>
                                <svg className="nn_editpro_icon"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                </svg>
                              </div>
                            }

                            {/* Hidden File Input */}
                          </div>
                          <div className="nn_edit_pro_ctn mt-3">
                            <h4 className="text-truncate">{userInfo?.fullName}</h4>
                            <h6><i class="fa fa-star" aria-hidden="true" style={{ color: "#ffc107" }}></i>{userInfo?.averageRating}</h6>
                            <ul className="text-center">
                              <li>Since {timeAgo(userInfo?.createdAt)}</li>
                              <li>{userInfo?.email}</li>
                              <li>
                                {userInfo?.status ?
                                  <>
                                    <i className="fa fa-envelope"></i>Email Verified
                                  </> : <>
                                    {
                                      loading?.emailVerify ? <BTNLoading className={"myplo_addressbtn1"} /> :
                                        <button className="myplo_addressbtn1" type="button" onClick={() => verifyEmailRequest()}>Verify Email</button>
                                    }
                                  </>}
                              </li>
                            </ul>
                          </div>

                          {
                            authID !== userInfo?._id &&
                            <div className="seller_edit_icons">
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  <i className="fa fa-ellipsis-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {
                                    loading?.block ? <BTNLoading /> :
                                      <button type="button" onClick={() => handleAction("block")}>Block User</button>
                                  }
                                  {
                                    loading?.report ? <BTNLoading /> :
                                      <button type="button" onClick={() => handleAction("report")}>Report User</button>
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          }

                        </div>
                        <Nav variant="tabs" className="nav-fill">
                          <Nav.Item>
                            <Nav.Link eventKey="selling">Selling</Nav.Link>
                          </Nav.Item>

                          {
                            authID === id &&
                            <>
                              <Nav.Item>
                                <Nav.Link eventKey="sold">Sold</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="favorites">Favorites</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item>
                                <Nav.Link eventKey="Offer">Offers</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="payoutmethod">Payout Method</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="bookings">Bookings</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="orders">Orders</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                <Nav.Link eventKey="sales">Sales</Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item>
                                <Nav.Link eventKey="helpsupports">  Help & Supports</Nav.Link>
                              </Nav.Item>
                            </>
                          }
                        </Nav>
                      </nav>
                    </Col>

                    <Col xs={12} lg={9} className="mt-lg-0 mt-4">
                      <Tab.Content>

                        {/* Selling Tab Content */}
                        <Tab.Pane eventKey="selling">
                          <div className=" selller_edit_sellpromain">
                            <div className="nn_edit_proname">Selling</div>
                            <div className="seller_edit_sellpro">
                              {
                                dashboardDetails?.sellingProducts?.length > 0 ?
                                  <Row className="seller_edit_sellpro_inner">
                                    {dashboardDetails?.sellingProducts?.map((item, idx) => (
                                      <Col lg={3} md={4} sm={6} className=" mb-4" key={idx}>
                                        <div className="product-boxes">
                                          <Link to="#">
                                            <div className="nn_homproductctn">
                                              <img alt="Product" src={item?.gallery?.length > 0 ? imgBaseURL() + item?.gallery[0] : defaultIMG} />
                                            </div>
                                            <div className="product-boxes_inner">
                                              <h4>{item?.title}</h4>
                                              <p>{item?.description}</p>
                                            </div>
                                          </Link>
                                        </div>
                                      </Col>
                                    ))}
                                  </Row>
                                  :
                                  <div className="nn_empty_tab text-center">
                                    <img src={EmptyTab} alt="Empty" />
                                    <p>
                                      <span>Sorry...</span> You have no sold products.
                                    </p>
                                  </div>
                              }
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* Sold Tab Content */}
                        <Tab.Pane eventKey="sold">
                          <div className=" selller_edit_sellpromain">
                            <div className="nn_edit_proname">Sold</div>

                            <div className="seller_edit_sellpro">
                              {
                                dashboardDetails?.soldProducts?.length > 0 ?
                                  <Row className="seller_edit_sellpro_inner">
                                    {dashboardDetails?.soldProducts?.map((item, idx) => (
                                      <Col lg={3} md={4} sm={6} className=" mb-4" key={idx}>
                                        <div className="product-boxes">
                                          <Link to="#">
                                            <div className="nn_homproductctn">
                                              <img alt="Product" src={item?.gallery?.length > 0 ? imgBaseURL() + item?.gallery[0] : defaultIMG} />
                                            </div>
                                            <div className="product-boxes_inner">
                                              <h4>{item?.title}</h4>
                                              <p>{item?.description}</p>
                                            </div>
                                          </Link>
                                        </div>
                                      </Col>
                                    ))}
                                  </Row>
                                  :
                                  <div className="nn_empty_tab text-center">
                                    <img src={EmptyTab} alt="Empty" />
                                    <p>
                                      <span>Sorry...</span> You have no sold products.
                                    </p>
                                  </div>
                              }
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* Favorites Tab Content */}
                        <Tab.Pane eventKey="favorites">
                          <div className=" selller_edit_sellpromain">
                            <div className="nn_edit_proname">Favorites</div>
                            <div className="seller_edit_sellpro">
                              {
                                dashboardDetails?.favourites?.length > 0 ?
                                  <Row className="seller_edit_sellpro_inner">
                                    {dashboardDetails?.favourites?.map((item, idx) => (
                                      <Col lg={3} md={4} sm={6} className=" mb-4" key={idx}>
                                        <div className="product-boxes">
                                          <Link to="#">
                                            <div className="nn_homproductctn">
                                              <img alt="Product" src={item?.productId?.gallery?.length > 0 ? imgBaseURL() + item?.productId?.gallery[0] : defaultIMG} />
                                            </div>
                                            <div className="product-boxes_inner">
                                              <h4>{item?.productId?.title}</h4>
                                              <p>{item?.productId?.description}</p>
                                            </div>
                                          </Link>
                                        </div>
                                      </Col>
                                    ))}
                                  </Row>
                                  :
                                  <div className="nn_empty_tab text-center">
                                    <img src={EmptyTab} alt="Empty" />
                                    <p>
                                      <span>Sorry...</span> You have no favourite product yet.
                                    </p>
                                  </div>
                              }

                            </div>
                          </div>
                        </Tab.Pane>

                        {/* Offer Tab Content */}
                        <Tab.Pane eventKey="Offer">
                          {
                            dashboardDetails &&
                            <Offer dashboardDetails={dashboardDetails} navigate={navigate} />
                          }
                        </Tab.Pane>

                        {/* Reviews Tab Content */}
                        <Tab.Pane eventKey="reviews">
                          <div className=" selller_edit_sellpromain">
                            <div className="nn_edit_proname">Reviews</div>
                            <div className="seller_edit_sellpro">
                              <div className="nn_empty_tab text-center">
                                <img src={EmptyTab} alt="Empty" />
                                <p>
                                  <span>Sorry...</span> You have no reviews yet.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* Add Payout Method Tab Content */}
                        <Tab.Pane eventKey="payoutmethod">
                          {
                            dashboardDetails &&
                            <PayoutMethod dashboardDetails={dashboardDetails} />
                          }
                        </Tab.Pane>

                        {/* Bookings Tab Content */}
                        <Tab.Pane eventKey="bookings">
                          <Booking />
                        </Tab.Pane>

                        {/* orders Tab Content */}
                        <Tab.Pane eventKey="orders" className="gis-services">
                          {
                            dashboardDetails &&
                            <Orders dashboardDetails={dashboardDetails} navigate={navigate} />
                          }
                        </Tab.Pane>

                        {/* sales Tab Content */}
                        <Tab.Pane eventKey="sales">
                          <div className=" selller_edit_sellpromain">
                            <div className="nn_edit_proname">Sales</div>
                            <div className="seller_edit_sellpro">
                              <div className="nn_empty_tab text-center">
                                <img src={EmptyTab} alt="Empty" />
                                <p>
                                  <span>Sorry...</span> You have no reviews yet.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        {/* helpsupports Tab Content */}
                        <Tab.Pane eventKey="helpsupports">
                          <div className=" selller_edit_sellpromain">
                            <div className="nn_edit_proname">Help & Supports</div>
                            <div className="seller_edit_sellpro">
                              <Form onSubmit={handleHelpNSupport}>
                                <Form.Group controlId="formEmail" className="mb-4">
                                  <Form.Label className="mb-4">Email<span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control type="email" value={auth("user")?.email} />
                                </Form.Group>

                                <Form.Group controlId="formFeedback" className="mb-lg-5 mb-4">
                                  <Form.Label className="mb-4">Description
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control as="textarea" rows={4} value={formValue?.feedback} onChange={(e) => setFormValue({ ...formValue, 'feedback': e.target.value })} />
                                </Form.Group>
                                <div className="text-end">
                                  {
                                    loading?.submit ?
                                      <BTNLoading className={"w-100 cmn-btn2"} />
                                      :
                                      <Button type="submit" className=" cmn-btn2"> Submit</Button>
                                  }
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Tab.Pane>

                      </Tab.Content>
                    </Col>
                  </Row>
              }
            </TabContainer>
          </Container>
        </div>
      </div>
      {/* Modal */}
      {showModal1 && (
        <div
          className="modal  fade show Add_addres_modal"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            inset: "0px",
          }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title m-0" id="exampleModalLabel">
                  Issue
                </h3>
                <button
                  type="button"
                  className="close"
                  onClick={handleCloseModal1}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="">
                  <div className="mb-3 form-group">
                    <label
                      className="form-label"
                      for="exampleForm.ControlInput1"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="exampleForm.ControlInput1"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label
                      className="form-label"
                      for="exampleForm.ControlInput1"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="exampleForm.ControlInput1"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label
                      className="form-label"
                      for="exampleForm.ControlTextarea1"
                    >
                      Subject
                    </label>
                    <textarea
                      rows="4"
                      id="exampleForm.ControlTextarea1"
                      className="form-control"
                      spellcheck="false"
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal1}
                >
                  Close
                </button>
                <button type="button" className="myplo_addressbtn1">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showmodal2 && (
        <EditProfileModal handleCloseModal2={handleCloseModal2} />
      )}

    </>
  );
};

export default EditProfile;
