/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import LoginCard from 'ui-component/cards/LoginCard';
import { ApiService } from 'services/apiservices';
import { useNavigate, useParams } from 'react-router';
import constant from 'services/constant';
import { APICALL } from 'helper/api/api';
import { auth, encryptLocalStorageData } from 'helper/Utility';
import logo from '../../assets/images/loginimg.png';
import { width } from '@mui/system';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
function Login() {
    const [VendorEmail, SetVendorEmail] = useState('');
    const [VendorPassword, SetVendorPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [msg, setMsg] = useState(null)
    const navigate = useNavigate();
    const authData = auth('admin');

    useEffect(() => {
        if (authData?.token) {
            if (authData?.role !== "admin") {
                navigate(`/`);
            } else {
                navigate(`/admin/dashboard`);
            }
        }
    }, []);

    const loginprocess = async () => {
        if (VendorEmail === '' || VendorPassword === '') {
            setMsg("Please Enter Email Or Password");
            return false;
        }
        const dataString = {
            email: VendorEmail,
            password: VendorPassword,
        };
        try {
            const res = await APICALL('admin/adminLogin', "post", dataString);
            console.log(res);
            if (res?.status) {
                const dataParam = {
                    token: res?.token,
                    firstName: res?.admin?.firstName,
                    lastName: res?.admin?.lastName,
                    email: res?.admin?.email,
                    id: res?.admin?._id,
                    role: res?.admin?.role,
                };
                encryptLocalStorageData("admin-secret", dataParam, "DoNotTryToAccess");
                navigate('/admin/dashboard');
            }
        } catch (error) {
            setMsg(error?.response?.data?.message)
        }
    };

    const handlePasswordVisibilityToggle = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <>
            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
                <Grid item md={4} justifyContent={'center'} alignItems={'center'}>
                    <LoginCard className="outer-divv">
                        <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                            <Grid item md={10} justifyContent={'center'} alignItems={'center'}>
                                <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                    <div className="app-logog text-center">
                                        <img src={logo} alt="" style={{ width: '150px', margin: 'auto' }} />
                                    </div>
                                    <div className="section-tittle text-center">
                                        <h1 variant="h1" className="tittle text-center" sx={{ pt: 0 }}>
                                            <span className="shape"></span>Welcome to Myplo!
                                        </h1>
                                    </div>
                                    <Grid item sm={12} xs={12} className='mt-3'>
                                        <FormControl fullWidth>
                                            <OutlinedInput
                                                placeholder="Email"
                                                onChange={(e) => SetVendorEmail(e.target.value)}
                                                className="form-inpitcustomfiled"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <FormControl fullWidth>
                                            <OutlinedInput
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(e) => SetVendorPassword(e.target.value)}
                                                className="form-inpitcustomfiled"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handlePasswordVisibilityToggle}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} xs={12} className='mt-2'>
                                    { msg && <Alert severity="error">{msg}</Alert>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button className="editprofile mt-sm-3 mt-3 mx-auto" onClick={loginprocess}>
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Grid>
            </Grid>
        </>
    );
}

export default Login;
